import React from "react";
import { Loading, ShowBase, useShowContext } from "react-admin";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Grid from "@mui/material/Grid";

import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";

const PollPresenter = () => {
  const location = useLocation();
  const pollId = location.pathname.split("/")?.[2] || "";
  const PollShowLayout = () => {
    const { isLoading, record, error } = useShowContext();

    if (isLoading) {
      return <Loading loadingPrimary="Loading" loadingSecondary="Poll is loading, just a moment!" />;
    }
    if (error) {
      return <div>Error!</div>;
    }
    return (
      <div>
        <Grid
          sx={{
            padding: "2rem",
            background: (theme) => theme.palette.primary.main,
            minHeight: "100vh",
          }}
        >
          <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
            <IconButton
              size="large"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                window.close();
              }}
            >
              <CloseIcon color="secondary" />
            </IconButton>
            <Box flexGrow={1} mr={{ xs: 0, sm: "0.5em" }}>
              <Card
                sx={{
                  m: "1rem",
                  p: "1rem",
                  minHeight: "150px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" component="div" align="center">
                  {record.question}
                </Typography>
              </Card>
            </Box>
            <Box sx={{ alignSelf: "center" }}>
              <Typography
                variant="h6"
                component="div"
                sx={{ px: 2 }}
                color="secondary"
                align="center"
              >
                0<br />
                Answers
              </Typography>
            </Box>
          </Box>
          <Grid container>
            {record.options.map((o) => (
              <Grid item xs={6} key={o.option}>
                <Card sx={{ m: "1rem" }}>
                  <CardContent
                    sx={{ minHeight: "150px", display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{ alignSelf: "center" }}
                      align="center"
                    >
                      {o.option}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          {record.status === "ENDED" && (
            <Grid container sx={{ textAlign: "center" }}>
              <Button
                size="large"
                variant="contained"
                color="success"
                startIcon={<CheckCircleIcon />}
                sx={{ px: 12, py: 2, mx: "auto", mt: 3 }}
              >
                VIEW RESULTS
              </Button>
            </Grid>
          )}
          {record.status === "ACTIVE" && (
            <Grid container sx={{ textAlign: "center" }}>
              <Button
                size="large"
                variant="contained"
                color="error"
                sx={{ px: 12, py: 2, mx: "auto", mt: 3 }}
              >
                END POLL
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    );
  };

  return (
    <>
      <ShowBase resource="polls" id={pollId}>
        <PollShowLayout />
      </ShowBase>
    </>
  );
};

export default PollPresenter;
