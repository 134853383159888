/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import React, { useContext, useState } from "react";
import { Confirm, Title, useNotify } from "react-admin";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { callApi } from "../../dataprovider/miscApis";
import Registrations from "./registrations";
import PassSelection from "./passSelection";
import { EventContext } from "../../dataprovider/eventProvider";
import Speakers from "./speakers";
import Live from "./live";
import QrCheck from "./qr";
import { mS } from "../../utils";

const ScanUtility = () => {
  const [data, setData] = useState([]);
  const [speaker, setSpeaker] = useState({});
  const [checkingQr, setCheckingQr] = useState(false);
  const [open, setOpen] = useState();
  const [reload, setReload] = useState(false);
  const [tab, setTab] = useState("live");

  const notify = useNotify();

  const [details, setDetails] = useState({});
  const { currentEvent } = useContext(EventContext);

  const [confirmId, setConfirmId] = useState(false);
  const [isLoading, setVerifyLoading] = useState(false);

  const onConfirmPass = (info) => {
    setReload(true);
    setDetails(info);
    setTimeout(() => {
      setReload(false);
    }, 1000);
  };

  const confirmDelegate = (id, type, confirmed) =>
    callApi(`registrations/verify-qr/${id}`, {
      pass: details.pass,
      confirmed,
    })
      .then((response) => {
        const { data: apiData } = response;
        const { data: delegate, scanned, notConfirmed } = apiData;
        setData(delegate.rows);
        setSpeaker({});
        if (scanned) {
          notify("Already Scanned", {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
          return null;
        }
        if (notConfirmed) {
          notify("Not Verfied", {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
          return null;
        }
        const [row] = delegate?.rows || [{}];
        if (details.allowPrinting) {
          printLabel(row, type);
        }
        if (!details.allowPrinting) {
          notify("Delegate Scanned Successfully.", {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        }
        return null;
      })
      .catch((err) => {
        if (err.response?.data?.error) {
          notify(`${err.response?.data?.error}`, { type: "warning" });
        } else {
          notify(`INVALID QR ${err.message}`, { type: "warning" });
        }
      })
      .finally(() => {
        setTimeout(() => {
          setCheckingQr(false);
        }, 1000);
      });

  const confirmSpeaker = (id) =>
    callApi(`speakers/verify-qr/${id}`)
      .then((response) => {
        const { data: apiData } = response;
        const { data: speakers, scanned } = apiData;
        setData([]);
        if (scanned) {
          notify("Already Scanned", {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
          return;
        }
        const row = speakers.rows?.[0] || {};
        setSpeaker(row);
        if (details.allowPrinting) {
          printSpeaker(row);
        }
        if (!details.allowPrinting) {
          notify("Speaker Scanned Successfully.", {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        }
      })
      .catch((err) => {
        notify(`INVALID QR ${err.message}`, { type: "warning" });
      })
      .finally(() => {
        setCheckingQr(false);
      });

  const onConfirm = async ({ id, status }) => {
    if (id) {
      if (status === "LEAD") {
        setConfirmId(id);
        return;
      }
      setVerifyLoading(true);
      await confirmDelegate(id, "d");
      setVerifyLoading(false);
    }
  };

  const printLabel = (info) => {
    if (currentEvent?.labelPrintUrl) {
      window.open(
        `${
          currentEvent.labelPrintUrl
        }?delegateName=${`${info.firstName} ${info.lastName}`}&delegateCompany=${
          info.company
        }&delegateId=${info.id}&delegateType=${info.delegateType}&qrurl=${encodeURI(
          info.shortenUrl
        )}`,
        "_blank"
      );
    }
  };

  const printSpeaker = (info) => {
    if (currentEvent?.labelPrintUrl) {
      window.open(
        `${currentEvent.labelPrintUrl}?delegateName=${`${info.name}`}&delegateCompany=${
          info.company
        }&delegateId=${info.id}&delegateType=SPEAKER&qrurl=${encodeURI(info.shortenUrl)}`,
        "_blank"
      );
    }
  };

  return (
    <>
      <Title
        title={
          details.pass ? (
            <>
              Scanning for: {details.pass}
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  setOpen(true);
                }}
                sx={{ alignSelf: "center", ml: mS && 1 }}
              >
                <EditIcon sx={{ color: "#000", height: mS ? "20px" : "15px" }} />
              </IconButton>
            </>
          ) : (
            <>Select a Pass</>
          )
        }
      />
      {details.pass ? (
        <>
          <Tabs
            value={tab}
            indicatorColor="primary"
            onChange={(e, value) => {
              setTab(value);
            }}
          >
            <Tab label="LIVE" value="live" />
            <Tab label="SCAN QR" value="qr" />
            <Tab label="SEARCH" value="search" />
            <Tab label="SPEAKERS" value="searchSpeakers" />
          </Tabs>
          {tab === "live" && <Live details={details} setTab={setTab} />}
          {tab === "qr" && (
            <QrCheck
              reload={reload}
              data={data}
              speaker={speaker}
              confirmDelegate={confirmDelegate}
              confirmSpeaker={confirmSpeaker}
              printLabel={printLabel}
              printSpeaker={printSpeaker}
              checkingQr={checkingQr}
              setCheckingQr={setCheckingQr}
            />
          )}
          {tab === "search" && (
            <Registrations
              printLabel={printLabel}
              details={details}
              onConfirm={onConfirm}
              isLoading={isLoading}
            />
          )}
          {tab === "searchSpeakers" && (
            <Speakers printLabel={printSpeaker} details={details} onConfirm={confirmSpeaker} />
          )}
        </>
      ) : (
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item lg={3} xs={10} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              component="label"
              size="large"
              sx={{ width: "100%", minHeight: "100px" }}
              onClick={() => setOpen(true)}
            >
              Select Pass
            </Button>
          </Grid>
        </Grid>
      )}
      <PassSelection open={open} setOpen={setOpen} onConfirm={onConfirmPass} />
      <Confirm
        isOpen={confirmId}
        loading={isLoading}
        title="Confirm Delegate"
        content="Are you sure you want to Confirm this LEAD"
        onConfirm={async () => {
          await confirmDelegate(confirmId, "d", true);
          setConfirmId(false);
        }}
        onClose={() => setConfirmId(false)}
      />
    </>
  );
};

export default ScanUtility;
