import React from "react";

import Box from "@mui/material/Box";

import { CreateButton, ListBase, Pagination, Title, TopToolbar, useListContext } from "react-admin";

import GridList from "./gridList";

const ListActions = () => (
  <TopToolbar>
    <CreateButton label="Create Poll" />
  </TopToolbar>
);

const ProductList = (props) => (
  <ListBase perPage={10} sort={{ field: "question", order: "ASC" }} {...props}>
    <ProductListView />
  </ListBase>
);

const ProductListView = () => {
  const { defaultTitle } = useListContext();
  return (
    <>
      <Title defaultTitle={defaultTitle} />
      <ListActions />
      <Box display="flex">
        <Box width="calc(100% - 1em)" style={{ paddingLeft: "1rem" }}>
          <GridList />
          <Pagination />
        </Box>
      </Box>
    </>
  );
};
export default ProductList;
