import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import { Card } from "@mui/material";
import { useNotify } from "react-admin";
import Grid from "@mui/material/Grid";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import IconButton from "@mui/material/IconButton";

import boop from "../../assets/beep-07a.mp3";
import InfoCard from "./InfoCard";
import SpeakerCard from "./speakerCard";

const QrCheck = ({
  onConfirm,
  confirmDelegate,
  confirmSpeaker,
  printLabel,
  printSpeaker,
  speaker,
  data,
  reload,
  checkingQr,
  setCheckingQr,
}) => {
  const audio = new Audio(boop);
  const notify = useNotify();
  const [facingMode, setFacingMode] = useState("user");

  const onQrCheck = async (result, error) => {
    if (result && !checkingQr) {
      setCheckingQr(true);
      audio.play();
      const params = (result?.text || "").split("/");
      const id = params?.[0] || "";
      const type = params?.[1] || "";
      if (type === "s" && id) {
        await confirmSpeaker(id, type);
      } else if (id) {
        await confirmDelegate(id, type);
      } else {
        notify("INVALID QR", { type: "warning" });
      }
    }

    if (error) {
      // eslint-disable-next-line no-console
      console.info(error);
    }
  };

  const onClick = (face) => {
    setFacingMode(face);
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: {
            exact: face,
          },
        },
      })
      .then((stream) => {
        const el = document.getElementById("QRVIDEO");
        el.srcObject = stream;
      })
      .catch(console.error);
  };
  const size = !!(
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
  );

  return (
    <Grid container spacing={2}>
      <Grid item lg={6} xs={12}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              minHeight: "33vh",
              marginTop: "1rem",
              padding: "0 1rem",
              width: "100%",
            }}
          >
            {!reload && (
              <Card style={{ height: "100%", width: "100%" }}>
                <QrReader
                  onResult={onQrCheck}
                  containerStyle={{
                    minWidth: "50vw",
                    minHeight: "50vh",
                    maxHeight: size ? "60vh" : "51vh",
                    transform: facingMode === "environment" ? "rotateY(180deg)" : "",
                  }}
                  videoContainerStyle={{ paddingTop: "80%" }}
                  videoId="QRVIDEO"
                  scanDelay={2000}
                />

                <IconButton
                  color="primary"
                  onClick={() => onClick(facingMode === "user" ? "environment" : "user")}
                  aria-label="upload picture"
                  component="label"
                >
                  <CameraswitchIcon />
                </IconButton>
              </Card>
            )}
          </div>
        </div>
      </Grid>
      <Grid item lg={6} xs={12}>
        {data && data.length > 0 && (
          <div
            style={{
              marginTop: "1rem",
              width: "100%",
            }}
          >
            <Card style={{ height: "100%", width: "100%", padding: "0 1rem" }}>
              <InfoCard data={data} printLabel={printLabel} onConfirm={onConfirm} />
            </Card>
          </div>
        )}
        {speaker?.id && (
          <div
            style={{
              marginTop: "1rem",
              width: "100%",
            }}
          >
            <Card style={{ height: "100%", width: "100%", padding: "0 1rem" }}>
              <SpeakerCard speaker={speaker} printLabel={printSpeaker} />
            </Card>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default QrCheck;
