// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCSy_qQLsV3SR5SzHVJKJBf9ppiClw_Jto",
  authDomain: "exp-platform-9918e.firebaseapp.com",
  projectId: "exp-platform-9918e",
  storageBucket: "exp-platform-9918e.appspot.com",
  messagingSenderId: "643280081474",
  appId: "1:643280081474:web:c373b6ad5efb29833f53db",
  measurementId: "G-SDGX5TP14C",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export const authObj = getAuth(app);

export default storage;
