import React, { useState } from "react";
import RedoIcon from "@mui/icons-material/Redo";
import { Button, Confirm, useUpdateMany, useRefresh, useNotify, useUnselectAll } from "react-admin";

const BulkUpdateButton = ({ resource, selectedIds, data, label, message }) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);

  const [updateMany, { isLoading }] = useUpdateMany(
    "registrations",
    { ids: selectedIds, data },
    {
      onSuccess: () => {
        refresh();
        notify("Updated");
        unselectAll();
      },
      onFailure: () => notify("Error: Not updated", "warning"),
    }
  );
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };

  return (
    <>
      <Button label={label} onClick={handleClick} alignIcon="right">
        <RedoIcon />
      </Button>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={label}
        content={message}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default BulkUpdateButton;
