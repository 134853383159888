export const schema = {
  FIRST_NAME: {
    prop: "firstName",
    type: String,
    required: true,
  },
  LAST_NAME: {
    prop: "lastName",
    type: String,
    required: true,
  },
  TITLE: {
    prop: "title",
    type: String,
    required: true,
  },
  COMPANY: {
    prop: "company",
    type: String,
    required: true,
  },
  COUNTRY: {
    prop: "country",
    type: String,
    required: true,
  },

  EMAIL: {
    prop: "email",
    type: String,
    required: true,
  },
  PHONE: {
    prop: "phone",
    type: String,
    required: true,
  },
  DELEGATETYPE: {
    prop: "delegateType",
    type: String,
    required: true,
  },
  STATUS: {
    prop: "status",
    type: String,
    oneOf: ["LEAD", "CONVERTED"],
    required: true,
  },
  LINKEDINURL: {
    prop: "linkedinUrl",
    type: String,
  },
};
