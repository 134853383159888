/* eslint-disable react/jsx-key */
import dayjs from "dayjs";
import React from "react";
import { List, Datagrid, TextField, FunctionField, RichTextField, SelectInput } from "react-admin";
import utc from "dayjs/plugin/utc";
const AgendaPanel = () => <RichTextField source="description" />;
const agendaFilters = [
  <SelectInput
    source="day"
    choices={[
      { id: 1, name: "Day 1" },
      { id: 2, name: "Day 2" },
    ]}
    resettable
    emptyText="Select"
    alwaysOn
  />,
];
export default (props) => (
  <List {...props} filters={agendaFilters} perPage={50} pagination={false}>
    <Datagrid rowClick="edit" expand={<AgendaPanel />}>
      <FunctionField
        sortable={false}
        source="startAt"
        label="Time"
        render={(record) => {
          dayjs.extend(utc);
          return `${dayjs.utc(record.startAt).format("HH:mm")} - ${dayjs
            .utc(record.endAt)
            .format("HH:mm")}`;
        }}
      />
      <TextField source="day" />
      <TextField source="title" />
    </Datagrid>
  </List>
);
