/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React from "react";
import { Admin, Resource, defaultTheme, CustomRoutes } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import { red } from "@mui/material/colors";
import { Route } from "react-router-dom";

import withClearCache from "./ClearCache";

import dataProvider from "./dataprovider";
import authProvider from "./authProvider";
import layout from "./layout";

import event from "./containers/event";
import admin from "./containers/admin";
import exhibitor from "./containers/exhibitor";
import speaker from "./containers/speaker";
import sponsor from "./containers/sponsor";
import dashboard from "./containers/dashboard";
import eventDashboard from "./containers/eventDashboard";
import agenda from "./containers/agenda";
import poll from "./containers/poll";
import { registration, agendaRequests, sponsorRequests } from "./containers/registration";
import EventProvider from "./dataprovider/eventProvider";
import QRcheck from "./containers/qrcheck";
import LabelPrint from "./containers/labelPrint";
import { getEventId } from "./utils";
import MyLoginPage from "./layout/login";
import ForgotPassword from "./layout/forgotPassword";
import PollPresenter from "./containers/poll/pollPresenter";
import eventMailTemplates from "./containers/eventMailTemplates";

const messages = {
  en: englishMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "en", {
  allowMissing: true,
});

const MainApp = () => {
  const eventId = getEventId();
  return (
    <EventProvider>
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        layout={layout}
        dashboard={eventId ? eventDashboard : dashboard}
        theme={appTheme}
        loginPage={MyLoginPage}
      >
        {(permissions) =>
          eventId ? (
            <>
              {permissions?.registrations?.view && <Resource {...registration(permissions)} />}
              {permissions?.registrations?.view && <Resource {...agendaRequests(permissions)} />}
              {permissions?.registrations?.view && <Resource {...sponsorRequests(permissions)} />}
              {permissions?.speaker?.view && <Resource {...speaker(permissions)} />}
              {permissions?.sponsor?.view && <Resource {...sponsor} />}
              {permissions?.agenda?.view && <Resource {...agenda} />}
              {permissions?.events?.view && <Resource {...event} />}
              {permissions?.events?.view && <Resource {...eventMailTemplates} />}
              {permissions?.admins?.view && <Resource {...admin} />}
              {permissions?.exhibitors?.view && <Resource {...exhibitor} />}
              {permissions?.agenda?.view && <Resource {...poll} />}
            </>
          ) : (
            <>
              {permissions?.events?.view && <Resource {...event} />}
              {permissions?.admins?.view && <Resource {...admin} />}
            </>
          )
        }
        <CustomRoutes>
          <Route path="/qrcheck" element={<QRcheck />} />
        </CustomRoutes>
        <CustomRoutes noLayout>
          <Route path="/labelprint" element={<LabelPrint />} />
        </CustomRoutes>
        <CustomRoutes noLayout>
          <Route path="/polls-presenter/:pollId" element={<PollPresenter />} />
        </CustomRoutes>
        <CustomRoutes noLayout>
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </CustomRoutes>
      </Admin>
    </EventProvider>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}
const appTheme = {
  ...defaultTheme,
  palette: {
    mode: "light",
    primary: {
      main: "#0A1F32",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ffffff",
      contrastText: "#0A1F32",
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ["Quantico", "sans-serif"].join(","),
  },
  sidebar: {
    borderRight: "1px solid #000",
  },
  components: {
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          // paddingLeft: "12px",
          "&.RaMenuItemLink-active": {
            background: "#6bd0dc",
            color: "#ffffff",
            "& .RaMenuItemLink-icon": {
              color: "#ffffff",
            },
          },
          "& .RaMenuItemLink-icon": {
            color: "#0A1F32",
          },
        },
      },
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: "light", // Some CSS
      },
    },
    "& .RaSidebar-drawerPaper": {
      backgroundColor: "red",
    },
  },
};

export default App;
