import React, { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import imageCompression from "browser-image-compression";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import ZoomOut from "@mui/icons-material/Remove";
import ZoomIn from "@mui/icons-material/Add";
import { useWatch } from "react-hook-form";
import { Button, useInput } from "react-admin";
import round from "lodash.round";

const getHeightWidth = (aspect) => {
  switch (aspect) {
    case "3/2":
      return { height: 266.67, width: 400 };
    case "4/3":
      return { height: 300, width: 400 };
    default:
      return { height: 400, width: 400 };
  }
};

const ImageInputWithCrop = (props) => {
  const { label, source, aspect } = props;
  const { width, height } = getHeightWidth(aspect);
  const classes = useStyles();
  const editor = useRef(null);
  const { field } = useInput(props);
  const src = useWatch({
    name: source,
  });
  const { onChange } = field;
  const [open, setOpen] = useState(false);
  const [scale, setScale] = useState(1);
  const handleClose = () => {
    setOpen(false);
  };
  const [image, setImage] = useState("http://example.com/initialimage.jpg");

  const onSave = async () => {
    if (editor?.current) {
      const canvas = editor.current.getImageScaledToCanvas();
      const options = {
        maxWidthOrHeight: 500,
        useWebWorker: true,
      };
      try {
        canvas.toBlob(async (blob) => {
          const canvasFile = new File([blob], "fileName.jpg", { type: "image/jpeg" });
          const compressedFile = await imageCompression(canvasFile, options);
          const fl = await imageCompression.getDataUrlFromFile(compressedFile);
          onChange(fl);
          setOpen(false);
        }, "image/jpeg");
      } catch (err) {
        Promise.reject(err);
      }
    }
    return null;
  };

  return (
    <div className={classes.container}>
      <Typography variant="caption" muted className={classes.text}>
        {label}
      </Typography>
      <Dropzone
        onDrop={(acceptedFiles) => {
          setImage(acceptedFiles[0]);
          setOpen(true);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p style={{ textAlign: "center" }}>Drop a file to upload, or click to select it.</p>
          </div>
        )}
      </Dropzone>

      <Typography variant="caption" muted className={classes.text}>
        Please upload an image of the following specifications - JPG/JPEG/PNG
      </Typography>
      {src && (
        <div className={classes.preview}>
          <img src={src} alt="preview" width="100%" />
        </div>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Crop Image</DialogTitle>
        <DialogContent>
          {image && (
            <AvatarEditor width={width} height={height} image={image} scale={scale} ref={editor} />
          )}
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <IconButton
              aria-label="zoom-out"
              onClick={() => {
                setScale((prev) => {
                  if (prev <= 0.4) {
                    return round(prev + 0.1, 1);
                  }
                  return prev;
                });
              }}
            >
              <ZoomOut />
            </IconButton>
            <Slider
              aria-label="Volume"
              value={scale}
              onChange={(e, newValue) => {
                setScale(newValue);
              }}
              min={0.5}
              max={2}
              step={0.1}
            />
            <IconButton aria-label="zoom-in">
              <ZoomIn
                onClick={() => {
                  setScale((prev) => {
                    if (prev <= 1.9) {
                      return round(prev + 0.1, 1);
                    }
                    return prev;
                  });
                }}
              />
            </IconButton>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onSave}>Done</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    padding: "12px 0px",
    borderBottom: "1px solid #efefef",
  },
  preview: {
    maxWidth: "200px",
    padding: "10px",
  },
  text: {
    color: "rgba(0, 0, 0, 0.6)",
  },
}));

export default ImageInputWithCrop;
