import React, { useContext } from "react";
import { TextInput, Create, SimpleForm, PasswordInput, CheckboxGroupInput } from "react-admin";
import { permissionsList } from "../../constants";
import { EventContext } from "../../dataprovider/eventProvider";

export default (props) => {
  const { events } = useContext(EventContext);
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="email" />
        <PasswordInput source="password" />
        <CheckboxGroupInput
          source="permissions"
          choices={permissionsList}
          row={false}
          defaultValue={permissionsList.filter((p) => p.checked).map((p) => p.id)}
        />
        <CheckboxGroupInput
          source="events"
          choices={events.map((e) => ({ id: e.id, name: e.name }))}
          row={false}
        />
      </SimpleForm>
    </Create>
  );
};
