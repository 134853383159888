import Icon from "@mui/icons-material/PollTwoTone";
import List from "./listView";
import Edit from "./edit";
import Create from "./create";

export default {
  list: List,
  edit: Edit,
  create: Create,

  icon: Icon,
  label: "Polls",
  options: { label: "Polls" },
  name: "polls",
};
