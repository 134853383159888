/* eslint-disable no-unused-vars */
import React from "react";
import Icon from "@mui/icons-material/AccountBoxTwoTone";
import AgendaIcon from "@mui/icons-material/PictureAsPdfTwoTone";
import SponsorIcon from "@mui/icons-material/AddBusinessTwoTone";
import List from "./list";
import ViewOnlyList from "./viewOnlyList";
import Edit from "./edit";
import Create from "./create";

export const registration = (permissions) => {
  const registrationPerms = permissions.registrations || {};

  let list;
  if (registrationPerms.view && registrationPerms.edit) {
    list = <List type="REGISTER" resource="registrations" />;
  } else if (registrationPerms.view) {
    list = <ViewOnlyList type="REGISTER" resource="registrations" />;
  }

  return {
    list,
    edit: registrationPerms.edit && <Edit type="REGISTER" />,
    create: registrationPerms.create && <Create type="REGISTER" />,
    icon: Icon,
    label: "Registrations",
    options: { label: "Registrations" },
    name: "registrations",
  };
};

export const agendaRequests = (permissions) => {
  const registrationPerms = permissions.registrations || {};

  let list;
  if (registrationPerms.view && registrationPerms.edit) {
    list = <List type="AGENDA" resource="agendaRequests" />;
  } else if (registrationPerms.view) {
    list = <ViewOnlyList type="AGENDA" resource="agendaRequests" />;
  }

  return {
    list,
    edit: registrationPerms.edit && <Edit type="AGENDA" />,
    create: registrationPerms.create && <Create type="AGENDA" />,
    icon: AgendaIcon,
    label: "Agenda Requests",
    options: { label: "Agenda Requests" },
    name: "agendaRequests",
  };
};

export const sponsorRequests = (permissions) => {
  const registrationPerms = permissions.registrations || {};

  let list;
  if (registrationPerms.view && registrationPerms.edit) {
    list = <List type="SPONSOR" resource="sponsorRequests" />;
  } else if (registrationPerms.view) {
    list = <ViewOnlyList type="SPONSOR" resource="sponsorRequests" />;
  }

  return {
    list,
    edit: registrationPerms.edit && <Edit type="SPONSOR" />,
    create: registrationPerms.create && <Create type="SPONSOR" />,
    icon: SponsorIcon,
    label: "Sponsor Requests",
    options: { label: "Sponsor Requests" },
    name: "sponsorRequests",
  };
};
