import React from "react";
import {
  TextInput,
  Create,
  SimpleForm,
  FileInput,
  FileField,
  BooleanInput,
  NumberInput,
  SelectInput,
  useNotify,
  useCreate,
  useRedirect,
} from "react-admin";
import Box from "@mui/material/Box";
import RichInput from "../../components/richInput";
import { uploadMedia } from "../../dataprovider/uploadProps";

export default (props) => {
  const notify = useNotify();
  const [create] = useCreate();
  const redirect = useRedirect();

  const save = async (data) => {
    let imageUrl = null;
    try {
      if (data.image instanceof Object) {
        imageUrl = await uploadMedia(data.image, "sponsors");
      }
    } catch (error) {
      notify("File is too large. max allowed size (100kb)", { type: "warning" });
      return;
    }
    create("sponsors", { data: { ...data, image: imageUrl } }, { returnPromise: true })
      .then(() => {
        notify("Sponsor Created");
        redirect("/sponsors");
      })
      .catch((err) => {
        console.error(err);
        notify("Something wrong happened. please try again", { type: "warning" });
      });
  };

  return (
    <Create {...props}>
      <SimpleForm warnWhenUnsavedChanges sx={{ maxWidth: 500 }} onSubmit={save}>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="name" helperText="(eg. Hewlett Packard)" isRequired fullWidth />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="slug" helperText="(eg. hewlett-packard)" isRequired fullWidth />
          </Box>
        </Box>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1}>
            <TextInput source="type" isRequired fullWidth />
          </Box>
        </Box>

        <RichInput size="large" source="bio" title="Bio" />
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <NumberInput
              source="panel"
              helperText="Range (1 .... 100)"
              isRequired
              fullWidth
              step={1}
            />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <NumberInput
              source="priority"
              isRequired
              fullWidth
              step={1}
              helperText="Range (1 .... 100)"
            />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <BooleanInput source="isActive" label="Activate Sponsor" />
          </Box>
        </Box>
        <SelectInput
          source="size"
          choices={[
            { id: 12, name: "XLarge" },
            { id: 6, name: "Large" },
            { id: 4, name: "Medium" },
            { id: 3, name: "Small" },
            { id: 2, name: "XSmall" },
          ]}
          fullWidth
        />
        <FileInput
          source="image"
          label="Sponsor Image"
          accept="image/jpeg, image/jpg, image/png"
          maxSize={100000}
          helperText={
            <span>
              Please upload an image of the following specifications - <br />
              <ol>
                <li>type:- JPG/JPEG</li>
                <li> size:- less than 100kb</li>
              </ol>
            </span>
          }
        >
          <FileField source="src" title="title" />
        </FileInput>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1}>
            <TextInput source="url" fullWidth />
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  );
};
