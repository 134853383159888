/* eslint-disable react/button-has-type */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, forwardRef, useRef } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import EmailEditor from "react-email-editor";
import { useFormContext, useWatch } from "react-hook-form";

import Slide from "@mui/material/Slide";
import { useInput } from "react-admin";
import { defaultTemplate } from "./template";

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function EmailBodyInput(props) {
  const { field } = useInput(props);
  const { setValue } = useFormContext();
  const { onChange, value } = field;
  const [open, setOpen] = useState(false);

  const htmlBody = useWatch({
    name: "htmlBody",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const emailEditorRef = useRef(null);

  const exportHtml = () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml(
      (d) => {
        const { design, html } = d;
        console.log("exportHtml", html);
        onChange(design);
        setValue("htmlBody", html);
        handleClose();
      },
      { minify: true, cleanup: true }
    );
  };

  const onReady = (unlayer) => {
    unlayer.loadDesign(value || defaultTemplate);
  };

  return (
    <>
      {htmlBody ? (
        <>
          <Button
            variant="contained"
            component="label"
            size="large"
            onClick={handleClickOpen}
            sx={{ mb: 3 }}
          >
            Edit Template
          </Button>
          <div style={{ display: "flex", width: "100%", marginBottom: "20px" }}>
            <iframe srcDoc={htmlBody} title="htmlBody" height="300px" width="100%"></iframe>
          </div>
        </>
      ) : (
        <div style={{ display: "flex", width: "100%" }}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%", width: "100%" }}
          >
            <Grid item lg={12} xs={10} sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                component="label"
                size="large"
                sx={{ width: "100%", minHeight: "100px" }}
                onClick={handleClickOpen}
              >
                Open Email Template Editor
              </Button>
            </Grid>
          </Grid>
        </div>
      )}

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Template Editor
            </Typography>

            <Button startIcon={<SaveIcon />} autoFocus color="inherit" onClick={exportHtml}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <EmailEditor ref={emailEditorRef} onReady={onReady} minHeight="95%" />
        </Grid>
      </Dialog>
    </>
  );
}
