export const defaultTemplate = {
  body: {
    id: "CZmdTdkfjv",
    rows: [
      {
        id: "z0KWG11PyT",
        cells: [1],
        values: {
          _meta: { htmlID: "u_row_2", htmlClassNames: "u_row" },
          anchor: "",
          columns: false,
          padding: "0px",
          hideable: true,
          deletable: true,
          draggable: true,
          selectable: true,
          hideDesktop: false,
          duplicatable: true,
          backgroundColor: "",
          backgroundImage: {
            url: "",
            size: "custom",
            repeat: "no-repeat",
            position: "center",
            fullWidth: true,
          },
          displayCondition: null,
          columnsBackgroundColor: "",
        },
        columns: [
          {
            id: "0drB2HRaIO",
            values: {
              _meta: { htmlID: "u_column_2", htmlClassNames: "u_column" },
              border: {},
              padding: "0px",
              borderRadius: "0px",
              backgroundColor: "#ffffff",
            },
            contents: [
              {
                id: "wOp-ntlU4O",
                type: "image",
                values: {
                  src: {
                    url: "https://placehold.co/600x200",
                    width: 600,
                    height: 200,
                    maxWidth: "100%",
                    autoWidth: false,
                  },
                  _meta: { htmlID: "u_content_image_1", htmlClassNames: "u_content_image" },
                  action: { name: "web", values: { href: "", target: "_blank" } },
                  anchor: "",
                  altText: "",
                  hideable: true,
                  deletable: true,
                  draggable: true,
                  textAlign: "center",
                  selectable: true,
                  hideDesktop: false,
                  duplicatable: true,
                  containerPadding: "10px",
                  displayCondition: null,
                },
              },
              {
                id: "tvqebgCgcM",
                type: "text",
                values: {
                  text: "<div style=\"color: #000000; white-space: normal; background-color: #ffffff; box-sizing: border-box; line-height: 16.8px; text-align: left; direction: ltr;\">\n<div><span class=\"size\" style=\"line-height: 19.6px;\"><span class=\"font\" style=\"line-height: 19.6px;\">Dear {{name}},</span></span><span class=\"font\" style=\"line-height: 19.6px;\"><br /></span></div>\n<div><span class=\"size\" style=\"line-height: 19.6px;\"><span class=\"font\" style=\"line-height: 19.6px;\">​</span></span><span class=\"font\" style=\"line-height: 19.6px;\"><br /></span></div>\n<div><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</div>\n</div>",
                  _meta: { htmlID: "u_content_text_3", htmlClassNames: "u_content_text" },
                  anchor: "",
                  fontSize: "14px",
                  hideable: true,
                  deletable: true,
                  draggable: true,
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverColor: "#0000ee",
                    linkHoverUnderline: true,
                  },
                  textAlign: "left",
                  lineHeight: "140%",
                  selectable: true,
                  hideDesktop: false,
                  duplicatable: true,
                  containerPadding: "10px",
                  displayCondition: null,
                },
              },
              {
                id: "rjPAh2Gyqu",
                type: "image",
                values: {
                  src: {
                    url: "https://api.qrserver.com/v1/create-qr-code/?size=300x300&data={{encodedUrl}}",
                    width: 300,
                    height: 300,
                    maxWidth: "50%",
                    autoWidth: false,
                  },
                  _meta: { htmlID: "u_content_image_2", htmlClassNames: "u_content_image" },
                  action: { name: "web", values: { href: "", target: "_blank" } },
                  anchor: "",
                  altText: "",
                  hideable: true,
                  deletable: true,
                  draggable: true,
                  textAlign: "left",
                  selectable: true,
                  hideDesktop: false,
                  duplicatable: true,
                  containerPadding: "10px",
                  displayCondition: null,
                },
              },
              {
                id: "cgKin5FQNa",
                type: "text",
                values: {
                  text: "<div style=\"color: #000000; white-space: normal; background-color: #ffffff; box-sizing: border-box; line-height: 16.8px; text-align: left; direction: ltr;\">\n<p style=\"box-sizing: border-box; line-height: 140%; margin: 20px 0px 0px;\"><a rel=\"noopener\" href=\"{{url}}\" target=\"_blank\" style=\"box-sizing: border-box;\" data-u-link-value=\"eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Int7dXJsfX0iLCJ0YXJnZXQiOiIifX0=\"><span class=\"size\" style=\"line-height: 19.6px;\"><span class=\"font\" style=\"line-height: 19.6px;\">Click here</span></span></a><span class=\"size\" style=\"line-height: 19.6px;\"><span class=\"font\" style=\"line-height: 19.6px;\"> if you are unable to see the QR Code<br /></span></span></p>\n</div>\n<div style=\"color: #000000; white-space: normal; background-color: #ffffff; box-sizing: border-box; line-height: 16.8px; text-align: left; direction: ltr;\">\n<p style=\"box-sizing: border-box; line-height: 140%; margin: 0px;\"><span class=\"size\" style=\"line-height: 19.6px;\"><span class=\"font\" style=\"line-height: 19.6px;\"> </span></span></p>\n<div><span class=\"size\" style=\"line-height: 19.6px;\"><span class=\"font\" style=\"line-height: 19.6px;\">Thank you,<br style=\"box-sizing: border-box;\" />Rapid Events</span></span></div>\n</div>",
                  _meta: { htmlID: "u_content_text_4", htmlClassNames: "u_content_text" },
                  anchor: "",
                  fontSize: "14px",
                  hideable: true,
                  deletable: true,
                  draggable: true,
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverColor: "#0000ee",
                    linkHoverUnderline: true,
                  },
                  textAlign: "left",
                  lineHeight: "140%",
                  selectable: true,
                  hideDesktop: false,
                  duplicatable: true,
                  containerPadding: "10px",
                  displayCondition: null,
                },
              },
            ],
          },
        ],
      },
    ],
    values: {
      _meta: { htmlID: "u_body", htmlClassNames: "u_body" },
      linkStyle: {
        body: true,
        linkColor: "#0000ee",
        linkUnderline: true,
        linkHoverColor: "#0000ee",
        linkHoverUnderline: true,
      },
      textColor: "#000000",
      fontFamily: { label: "Arial", value: "arial,helvetica,sans-serif" },
      popupWidth: "600px",
      popupHeight: "auto",
      borderRadius: "10px",
      contentAlign: "center",
      contentWidth: "500px",
      popupPosition: "center",
      preheaderText: "",
      backgroundColor: "#FFFFFF",
      backgroundImage: {
        url: "",
        size: "custom",
        repeat: "no-repeat",
        position: "center",
        fullWidth: true,
      },
      contentVerticalAlign: "center",
      popupBackgroundColor: "#FFFFFF",
      popupBackgroundImage: {
        url: "",
        size: "cover",
        repeat: "no-repeat",
        position: "center",
        fullWidth: true,
      },
      popupCloseButton_action: {
        name: "close_popup",
        attrs: { onClick: "document.querySelector('.u-popup-container').style.display = 'none';" },
      },
      popupCloseButton_margin: "0px",
      popupCloseButton_position: "top-right",
      popupCloseButton_iconColor: "#000000",
      popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
      popupCloseButton_borderRadius: "0px",
      popupCloseButton_backgroundColor: "#DDDDDD",
    },
    footers: [],
    headers: [],
  },
  counters: { u_row: 2, u_column: 2, u_content_text: 4, u_content_image: 2, u_content_heading: 1 },
  schemaVersion: 16,
};
