/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useContext } from "react";
import {
  Datagrid,
  List,
  BooleanField,
  useListContext,
  TextInput,
  DateField,
  FunctionField,
  downloadCSV,
} from "react-admin";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import copy from "copy-to-clipboard";
import jsonExport from "jsonexport/dist";
import { EventContext } from "../../dataprovider/eventProvider";

const exporter = (registrations) => {
  const speakersExport = registrations.map((registration) => {
    const {
      firstName,
      lastName,
      title,
      phone,
      email,
      company,
      country,
      privacyPolicy,
      udf,
      createdAt,
    } = registration;
    return {
      firstName,
      lastName,
      title,
      company,
      country,
      phone,
      email,
      dataConsent: privacyPolicy ? "YES" : "NO",
      profile: udf.profile,
      createdAt,
    };
  });
  jsonExport(
    speakersExport,
    {
      headers: [
        "firstName",
        "lastName",
        "title",
        "company",
        "country",
        "phone",
        "email",
        "dataConsent",
        "profile",
        "createdAt",
      ], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "delegates");
    }
  );
};
function TabbedDatagrid(props) {
  const listContext = useListContext();
  const { filterValues, setFilters } = listContext;
  const handleChange = (event, value) => {
    setFilters({ ...filterValues, status: value });
  };
  const { type } = props;
  const { currentEvent } = useContext(EventContext);
  const fields = [
    <FunctionField
      label="Name"
      source="firstName"
      render={(record) => <b>{`${record.firstName} ${record.lastName}`}</b>}
      key="name"
    />,
    <FunctionField
      label="Details"
      render={(record) => (
        <span>
          {record.title},
          <br />
          <br />
          <b>
            {record.company},
            <br />
            {record.country}
          </b>
        </span>
      )}
      key="Info"
    />,
    <FunctionField
      label="Phone"
      source="phone"
      render={(record) => (
        <Tooltip title="Click to copy">
          <Link
            component="button"
            onClick={() => {
              copy(`+${record.phone}`);
            }}
            underline="none"
          >
            +{record.phone}
          </Link>
        </Tooltip>
      )}
      key="phone"
    />,
    <FunctionField
      label="Email"
      source="email"
      render={(record) => (
        <Tooltip title="Click to copy">
          <Link
            component="button"
            onClick={() => {
              copy(record.email);
            }}
            underline="none"
          >
            {record.email}
          </Link>
        </Tooltip>
      )}
      key="email"
    />,

    <BooleanField
      source="privacyPolicy"
      key="privacyPolicy"
      label="Data Consent"
      TrueIcon={() => <CheckCircleSharpIcon color="success" />}
      FalseIcon={() => <CancelSharpIcon color="warning" />}
    />,
    <DateField source="createdAt" key="createdAt" />,
  ];

  const { statusList: statuses } = currentEvent;
  let statusList = [];
  if (statuses) {
    statusList = statuses[type];
  }

  const DataGrid = () => (
    <Datagrid
      size="small"
      optimized
      sx={{
        color: "success.main",
        overflow: "auto",
      }}
      bulkActionButtons={false}
    >
      {[...fields]}
    </Datagrid>
  );

  return (
    <>
      <Tabs value={filterValues?.status} indicatorColor="primary" onChange={handleChange}>
        {statusList
          ?.filter((s) => s.id !== "LEAD")
          .map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
      </Tabs>
      <Divider />
      <div>
        <DataGrid {...props} />
      </div>
    </>
  );
}

const RegistrationFilters = [<TextInput label="Search" source="search" alwaysOn key={0} />];

export default function OrderList(props) {
  return (
    <List
      {...props}
      sort={{ field: "createdAt", order: "DESC" }}
      filterDefaultValues={{ status: "CONVERTED" }}
      perPage={15}
      filters={RegistrationFilters}
      hasCreate={false}
      hasEdit={false}
      exporter={exporter}
    >
      <TabbedDatagrid {...props} />
    </List>
  );
}
