/* eslint-disable no-console */
import React from "react";
import { TextInput, Edit, SimpleForm, SelectInput } from "react-admin";

export default (props) => (
  <Edit {...props} mutationMode="optimistic">
    <SimpleForm sx={{ maxWidth: 500 }}>
      <TextInput source="name" required fullWidth />
      <TextInput source="email" required fullWidth />
      <TextInput source="company" required fullWidth />
      <SelectInput
        source="access"
        required
        fullWidth
        choices={[
          { id: "FULL", name: "Full Access" },
          { id: "SCAN_ONLY", name: "Scan Only" },
        ]}
      />
    </SimpleForm>
  </Edit>
);
