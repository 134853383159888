/* eslint-disable no-console */
import React, { useContext } from "react";
import { TextInput, Edit, SimpleForm, CheckboxGroupInput } from "react-admin";
import { permissionsList } from "../../constants";
import { EventContext } from "../../dataprovider/eventProvider";

export default (props) => {
  const { events } = useContext(EventContext);
  return (
    <Edit {...props} mutationMode="optimistic">
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="email" />
        <CheckboxGroupInput source="permissions" choices={permissionsList} row={false} />
        <CheckboxGroupInput
          source="events"
          choices={events.map((e) => ({ id: e.id, name: e.name }))}
          row={false}
        />
      </SimpleForm>
    </Edit>
  );
};
