/* eslint-disable no-console */
import React, { useContext, useState } from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  useRecordContext,
  BooleanInput,
  SelectInput,
  FormDataConsumer,
  BooleanField,
  Toolbar,
  SaveButton,
  DeleteButton,
  Button,
  useNotify,
  useRefresh,
  DateField,
  SelectArrayInput,
  useRedirect,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import { EventContext } from "../../dataprovider/eventProvider";
import { callApi } from "../../dataprovider/miscApis";

export default (props) => {
  const { currentEvent } = useContext(EventContext);
  const { udf, statusList: statuses, passes: passesList } = currentEvent;
  const { type } = props;
  let statusList = [];
  if (statuses) {
    statusList = statuses[type];
  }
  let fields = [];
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const [sendingConfirmation, setSendingConfirmation] = useState(false);

  if (udf) {
    const additionalFields = Object.keys(udf).map((field) => {
      if (udf[field].type === "boolean") {
        return (
          <Grid item xs={6} key={`udf.${udf[field].name}`}>
            <BooleanInput
              source={`udf.${udf[field].name}`}
              label={udf[field].label}
              fullWidth
              helperText={false}
            />
          </Grid>
        );
      }
      if (udf[field].type === "select") {
        return (
          <Grid item xs={6} key={`udf.${udf[field].name}`}>
            <SelectInput
              source={`udf.${udf[field].name}`}
              label={udf[field].label}
              fullWidth
              choices={udf[field].options}
              helperText={false}
            />
          </Grid>
        );
      }
      return (
        <Grid item xs={6} key={`udf.${udf[field].name}`}>
          <TextInput
            source={`udf.${udf[field].name}`}
            label={udf[field].label}
            fullWidth
            helperText={false}
          />
        </Grid>
      );
    });
    fields = [additionalFields];
  }

  const SpeakerTitle = () => {
    const record = useRecordContext();
    return <span>{record ? `${record.firstName} ${record.lastName || ""}` : ""}</span>;
  };

  const onClickSendConfirmation = (id) => {
    setSendingConfirmation(true);
    callApi(
      "registrations/send-confirmation-mail",
      { ids: [id], confirmationSent: dayjs() },
      "POST"
    )
      .then(() => {
        notify("Confirmation Sent Successfully");
        setSendingConfirmation(false);
        refresh();
      })
      .catch(() => {
        notify("Email not configured", { type: "warning" });
      });
  };

  const RegistrationToolbar = () => {
    const { getValues } = useFormContext();
    const values = getValues();
    return (
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <SaveButton />
          <Button
            label="Send Confirmation"
            variant="outlined"
            sx={{ padding: 1, ml: 2 }}
            onClick={() => onClickSendConfirmation(values.id, values)}
            disabled={sendingConfirmation}
          />
        </div>
        <DeleteButton />
      </Toolbar>
    );
  };

  const onSuccess = (prop) => {
    const typeOfRequest = prop.type;
    let redirectTo = "/registrations";
    if (typeOfRequest === "AGENDA") {
      redirectTo = "/agendaRequests";
    }
    if (typeOfRequest === "SPONSOR") {
      redirectTo = "/sponsorRequests";
    }

    redirect(redirectTo);
    refresh();
  };

  return (
    <>
      <Edit
        {...props}
        mutationMode="optimistic"
        title={<SpeakerTitle />}
        mutationOptions={{ onSuccess }}
      >
        <SimpleForm warnWhenUnsavedChanges sx={{ maxWidth: 500 }} toolbar={<RegistrationToolbar />}>
          <FormDataConsumer>
            {({ formData }) =>
              formData.confirmationSent && (
                <Card sx={{ maxWidth: 345, mb: 3, backgroundColor: "#b9f6ca" }}>
                  <CardContent>
                    <Typography variant="body2">
                      Confirmation Sent On -
                      <DateField showTime source="confirmationSent" />
                    </Typography>
                  </CardContent>
                </Card>
              )
            }
          </FormDataConsumer>

          <Grid container spacing={1}>
            <Grid item lg={6} xs={6}>
              <TextInput source="firstName" fullWidth helperText={false} required />
            </Grid>
            <Grid item lg={6} xs={6}>
              <TextInput source="lastName" fullWidth helperText={false} required />
            </Grid>
            <Grid item lg={6} xs={6}>
              <TextInput source="title" fullWidth helperText={false} required />
            </Grid>
            <Grid item lg={6} xs={6}>
              <TextInput source="company" fullWidth helperText={false} required />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextInput source="country" fullWidth helperText={false} required />
            </Grid>
            <Grid item lg={6} xs={6}>
              <TextInput source="phone" fullWidth helperText={false} required />
            </Grid>
            <Grid item lg={6} xs={6}>
              <TextInput source="promo" fullWidth helperText={false} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextInput source="email" fullWidth helperText={false} required />
            </Grid>
            <Grid item lg={12} xs={12} sx={{ mb: 2 }}>
              <SelectArrayInput
                source="passes"
                choices={passesList || []}
                fullWidth
                helperText="Select Passes (You can select multiple passes)"
              />
            </Grid>
            {[...fields]}
          </Grid>

          <Grid container spacing={1}>
            <Grid item lg={6} xs={6}>
              <SelectInput
                source="status"
                choices={statusList.filter((s) => !s.pass) || []}
                fullWidth
                isRequired
              />
            </Grid>
            <Grid item lg={6} xs={6}>
              <SelectInput
                source="type"
                label="Request Type"
                helperText="Select one of Register, Agenda, Sponsor"
                choices={[
                  { id: "REGISTER", name: "REGISTER" },
                  { id: "SPONSOR", name: "SPONSOR" },
                  { id: "AGENDA", name: "AGENDA" },
                ]}
                fullWidth
                isRequired
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mb: 3 }}>
            <Grid item lg={12} xs={12}>
              <SelectInput
                source="delegateType"
                helperText="Select One of Delegate, Sponsor, Organiser, Media"
                choices={[
                  { id: "DELEGATE", name: "DELEGATE" },
                  { id: "SPONSOR", name: "SPONSOR" },
                  { id: "ORGANISER", name: "ORGANISER" },
                  { id: "MEDIA", name: "MEDIA" },
                ]}
                fullWidth
                isRequired
              />
            </Grid>
          </Grid>
          <FormDataConsumer>
            {() => (
              <Grid container spacing={1}>
                <Typography gutterBottom variant="body2" sx={{ ml: 2 }}>
                  <BooleanField source="optIn" />
                  Opt In
                </Typography>
                <Typography gutterBottom variant="body2" sx={{ ml: 2 }}>
                  <BooleanField source="privacyPolicy" />
                  Privacy Policy
                </Typography>
              </Grid>
            )}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    </>
  );
};
