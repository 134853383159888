/* eslint-disable no-unused-vars */
import React from "react";
import { TextInput, Edit, SimpleForm, BooleanInput } from "react-admin";

import EmailBodyInput from "../../components/EmailBodyInput";

export default (props) => (
  <Edit {...props}>
    <SimpleForm warnWhenUnsavedChanges sx={{ maxWidth: 500 }}>
      <TextInput source="templateName" isRequired fullWidth />
      <TextInput source="subject" isRequired fullWidth />
      <EmailBodyInput source="templateSchema" isRequired />
      <BooleanInput source="isActive" />
    </SimpleForm>
  </Edit>
);
