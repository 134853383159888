/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import React, { useEffect } from "react";
// import { Helmet } from "react-helmet";
import QRCode from "react-qr-code";

const QrCheck = () => {
  useEffect(() => {
    // setInterval(() => {
    window.print();
    window.close();
    // }, 500);
  }, []);
  const { href } = window.location;
  const params = href.split("?")[1];
  const searchParams = new URLSearchParams(params);
  const delegateName = searchParams.get("delegateName");
  const delegateCompany = searchParams.get("degelateCompany");
  const delegateId = searchParams.get("delegateId");

  return (
    <>
      <div
        style={{
          height: "70mm",
          display: "flex",
          justifyContent: "center",
          width: "75mm",
        }}
      >
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#9da3fc",
            alignItems: "center",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "20px 2px 0",
            boxSizing: "content-box",
          }}
        >
          <h1
            style={{
              fontSize: "7mm",
              fontFamily: "'Roboto', sans-serif",
              fontWeight: "900",
              wordWrap: "break-word",
              textTransform: "uppercase",
              margin: "10px 0",
            }}
            id="delegateName"
          >
            {delegateName}
          </h1>
          <h3
            style={{
              fontSize: "5.5mm",
              fontFamily: "'Roboto', sans-serif",
              fontWeight: "900",
              wordWrap: "break-word",
              margin: "10px 0",
              textTransform: "uppercase",
            }}
            id="delegateCompany"
          >
            {delegateCompany}
          </h3>
          <div
            style={{
              height: "20mm",
              width: "20mm",
            }}
          >
            <QRCode
              value={`https://ksa.digitaltwin-summit.com/delegateInfo/${delegateId}`}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              viewBox="0 0 256 256"
              size={256}
            />
            {/* <img
              src={`https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=https://ksa.digitaltwin-summit.com/delegateInfo/${delegateId}`}
              width="100%"
              alt="Qr code"
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default QrCheck;
