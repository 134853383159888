import React from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  NumberInput,
  useRecordContext,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  ReferenceInput,
} from "react-admin";
import Box from "@mui/material/Box";
import DateTimeInput from "../../components/DateTimeInput";
import RichInput from "../../components/richInput";
import { formatDateTime, parseDateTime } from "../../utils";

const AgendaTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.title}` : ""}</span>;
};

export default (props) => (
  <Edit {...props} mutationMode="undoable" title={<AgendaTitle />}>
    <SimpleForm sx={{ maxWidth: 500 }}>
      <SelectInput
        source="type"
        choices={[
          { id: "Panel Discussion", name: "Panel Discussion" },
          { id: "Keynote", name: "Keynote" },
        ]}
        fullWidth
        sx={{ mb: 2 }}
        helperText="Select type of agenda. (eg. Panel Discussion, Keynote)"
      />
      <TextInput source="title" fullWidth />
      <RichInput source="description" fullWidth />

      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <DateTimeInput
            source="startAt"
            label="Start At"
            parse={parseDateTime}
            format={formatDateTime}
            fullWidth
            options={{ ampmInClock: false, clearable: true }}
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <DateTimeInput
            source="endAt"
            label="End At"
            parse={parseDateTime}
            fullWidth
            format={formatDateTime}
            options={{ ampm: false, clearable: true }}
          />
        </Box>
      </Box>
      <ReferenceArrayInput
        source="speakers"
        reference="speakers"
        perPage={100}
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectArrayInput
          parse={(value) => (value === "not defined" ? undefined : value)}
          label="Speakers"
          helperText="Add speakers to this title"
          fullWidth
          sx={{ mb: 1 }}
        />
      </ReferenceArrayInput>
      <ReferenceInput
        source="moderator"
        reference="speakers"
        perPage={100}
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput
          label="Moderator"
          helperText="Add a moderator to this title"
          fullWidth
          format={(value) => (value === undefined ? "not defined" : value)}
          optionText="name"
          sx={{ mb: 1 }}
        />
      </ReferenceInput>

      <NumberInput source="day" fullWidth />
    </SimpleForm>
  </Edit>
);
