/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Button,
  useUnselectAll,
  useNotify,
  useRefresh,
  useGetList,
  useRedirect,
} from "react-admin";
import MailIcon from "@mui/icons-material/Mail";
import IconSave from "@mui/icons-material/Save";
import IconCancel from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";
import { callApi } from "../../dataprovider/miscApis";

function ConfirmButton({ selectedIds }) {
  const [showDialog, setShowDialog] = useState(false);
  const [sendingConfirmation, setSendingConfirmation] = useState(false);
  const [template, setTemplate] = useState("");
  const [templates, setTemplates] = useState([]);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const unselectAll = useUnselectAll();

  const { data, loading } = useGetList("eventMailTemplates", {
    pagination: { page: 1, perPage: 999 },
    sort: { field: "createdAt", order: "DESC" },
    filter: {
      isActive: 1,
    },
  });

  const handleChange = (e) => {
    setTemplate(e.target.value);
  };
  const handleClick = () => {
    setShowDialog(true);
  };
  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const onClickSendConfirmation = async () => {
    setSendingConfirmation(true);
    return callApi(
      "registrations/send-confirmation-mail",
      { ids: selectedIds, confirmationSent: dayjs(), templateId: template },
      "POST"
    )
      .then(() => {
        notify("Confirmation Sent Successfully");
        setShowDialog(false);
        unselectAll();
        refresh();
      })
      .catch(() => {
        notify("Email not configured", { type: "warning" });
      })
      .finally(() => {
        setSendingConfirmation(false);
      });
  };

  useEffect(() => {
    if (data?.length > 0) {
      setTemplates(data);
    }
  }, [data]);

  return (
    <>
      <Button onClick={handleClick} label="Send Confirmation" disabled={sendingConfirmation}>
        <MailIcon />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Select Template">
        <DialogTitle>Select Template</DialogTitle>
        <DialogContent>
          {!loading && templates.length === 0 ? (
            <DialogContentText>No Templates Available</DialogContentText>
          ) : (
            <Select
              value={template}
              onChange={handleChange}
              inputProps={{
                name: "template",
                id: "template",
              }}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {templates.map((o) => (
                <MenuItem key={o.id} value={o.id}>
                  {o.templateName}
                </MenuItem>
              ))}
            </Select>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={handleCloseClick}
            disabled={sendingConfirmation}
            sx={{ margin: "20px" }}
          >
            <IconCancel />
          </Button>
          {!loading && templates.length === 0 ? (
            <Button
              label="Create Template"
              onClick={() => {
                redirect("/eventMailTemplates");
              }}
              variant="contained"
              sx={{ margin: "20px" }}
            >
              <IconSave />
            </Button>
          ) : (
            <Button
              label="ra.action.confirm"
              onClick={onClickSendConfirmation}
              variant="contained"
              disabled={!template || sendingConfirmation}
              sx={{ margin: "20px" }}
            >
              {sendingConfirmation ? <CircularProgress color="inherit" size={20} /> : <IconSave />}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmButton;
