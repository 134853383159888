import React from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  useRecordContext,
  SelectInput,
  ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
  Button,
} from "react-admin";

const AgendaTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.title}` : ""}</span>;
};

export default (props) => (
  <Edit {...props} mutationMode="undoable" title={<AgendaTitle />}>
    <SimpleForm sx={{ maxWidth: 500 }}>
      <TextInput source="question" fullWidth />
      <ArrayInput source="options" fullWidth>
        <SimpleFormIterator inline addButton={<Button variant="outlined">Add Options</Button>}>
          <TextInput source="option" helperText={false} fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
      <ReferenceInput
        source="agendaId"
        reference="agendas"
        perPage={100}
        sort={{ field: "day", order: "ASC" }}
      >
        <SelectInput
          label="Agenda"
          helperText="Add Poll to agenda item"
          fullWidth
          format={(value) => (value === undefined ? "not defined" : value)}
          optionText="title"
          sx={{ mb: 1 }}
        />
      </ReferenceInput>
    </SimpleForm>{" "}
  </Edit>
);
