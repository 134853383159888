/* eslint-disable no-unused-vars */
import React from "react";
import { TextInput, Create, SimpleForm, BooleanInput } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { uploadMedia } from "../../dataprovider/uploadProps";
import EmailBodyInput from "../../components/EmailBodyInput";

export default (props) => (
  <Create {...props}>
    <SimpleForm warnWhenUnsavedChanges sx={{ maxWidth: 500 }}>
      <TextInput source="templateName" isRequired fullWidth />
      <TextInput source="subject" isRequired fullWidth />
      <EmailBodyInput source="templateSchema" isRequired />
      <BooleanInput source="isActive" />
    </SimpleForm>
  </Create>
);
