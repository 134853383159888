/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import {
  TextInput,
  Create,
  SimpleForm,
  FileInput,
  FileField,
  BooleanInput,
  useNotify,
  useCreate,
  useRedirect,
} from "react-admin";
import Box from "@mui/material/Box";
import { uploadBase64Media, uploadMedia } from "../../dataprovider/uploadProps";
import RichInput from "../../components/richInput";
import ImageInputWithCrop from "../../components/ImageInputWithCrop";

export default (props) => {
  const notify = useNotify();
  const [create] = useCreate();
  const redirect = useRedirect();
  const save = async (data) => {
    let imageUrl = null;
    let companyImageUrl = null;
    try {
      if (data.image) {
        imageUrl = await uploadBase64Media(data.image);
      }
      if (data.companyImage instanceof Object) {
        companyImageUrl = await uploadMedia(data.companyImage, "speakers");
      }
    } catch (error) {
      notify("File is too large. max allowed size (100kb)", { type: "warning" });
      return;
    }
    await create(
      "speakers",
      { data: { ...data, image: imageUrl, companyImage: companyImageUrl } },
      { returnPromise: true }
    )
      .then(() => {
        notify("Speaker Created");
        redirect("/speakers");
      })
      .catch((err) => {
        console.error(err);
        notify("Something wrong happened. please try again", { type: "warning" });
      });
  };

  return (
    <>
      <Create {...props}>
        <SimpleForm warnWhenUnsavedChanges sx={{ maxWidth: 500 }} onSubmit={save}>
          <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <TextInput source="name" required fullWidth helperText={false} />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <TextInput source="title" required fullWidth helperText={false} />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <TextInput source="highlightText" fullWidth helperText={false} />
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <TextInput source="company" required fullWidth helperText={false} />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <TextInput source="country" required fullWidth helperText={false} />
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <TextInput source="phone" fullWidth helperText={false} />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <TextInput source="email" fullWidth helperText={false} />
            </Box>
          </Box>
          <BooleanInput source="isHighlighted" />
          <RichInput size="large" source="bio" title="Bio" helperText={false} />
          <ImageInputWithCrop source="image" label="Speaker Image" />
          <FileInput
            source="companyImage"
            label="Company Image"
            accept="image/jpeg, image/jpg, image/png"
            maxSize={50000}
            helperText={
              <span>
                Please upload an image of the following specifications - <br />
                <ol>
                  <li>aspect ratio:- 100px width</li>
                  <li>type:- JPG/JPEG</li>
                  <li> size:- less than 50kb</li>
                </ol>
              </span>
            }
          >
            <FileField source="src" title="title" />
          </FileInput>
          <BooleanInput source="hideSpeaker" />
        </SimpleForm>
      </Create>
    </>
  );
};
