import Icon from "@mui/icons-material/AttachEmailOutlined";
import List from "./list";
import Edit from "./edit";
import Create from "./create";

export default {
  list: List,
  edit: Edit,
  create: Create,
  icon: Icon,
  label: "Email Templates",
  options: { label: "Email Templates" },
  name: "eventMailTemplates",
};
