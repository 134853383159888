/* eslint-disable no-console */
import Icon from "@mui/icons-material/PersonTwoTone";
import List from "./list";
import Edit from "./edit";
import Create from "./create";

export default (permissions) => {
  const speakerPerms = permissions.speaker || {};
  return {
    list: speakerPerms.view && List,
    edit: speakerPerms.edit && Edit,
    create: speakerPerms.create && Create,
    icon: Icon,
    label: "Speakers",
    options: { label: "Speakers" },
    name: "speakers",
  };
};
