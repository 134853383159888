import React from "react";
import { Button } from "react-admin";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import PrintIcon from "@mui/icons-material/Print";
import Grid from "@mui/material/Grid";

const InfoCard = ({ data, printLabel, onConfirm }) => {
  const LabelValue = ({ label, value, bigValue, rightAlign }) => (
    <>
      <Typography variant="body2" style={{ color: "#9e9e9e", textAlign: rightAlign && "right" }}>
        {label}
      </Typography>
      <Typography
        variant={bigValue ? "h6" : "body1"}
        style={{ wordWrap: "break-word", textAlign: rightAlign && "right" }}
      >
        {value}
      </Typography>
    </>
  );

  const delegateColorMap = {
    DELEGATE: {
      color: "#673ab7",
    },
    SPONSOR: {
      color: "#4caf50",
    },
    ORGANISER: {
      color: "#ff9800",
    },
    MEDIA: {
      color: "#795548",
    },
  };

  return (
    <div style={{ padding: "1.5rem 0", marginBottom: "1.5rem" }}>
      {data.map((d) => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ marginTop: 4 }}>
              <Typography
                variant="h5"
                style={{ wordWrap: "break-word", color: delegateColorMap?.[d.delegateType]?.color }}
              >
                {d.delegateType}
              </Typography>
              <LabelValue label="" value={d.status} />
              <Typography variant="subtitle2" style={{ color: "#9e9e9e" }}>
                {dayjs().format("DD-MM-YYYY HH:mm")}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginTop: 4, textAlign: "right" }}>
              {d.status === "LEAD" ? (
                <Button
                  variant="contained"
                  color="error"
                  size="lg"
                  onClick={() => onConfirm(d)}
                  label=" Confirm & Print"
                >
                  <PrintIcon style={{ marginRight: 2 }} />
                </Button>
              ) : (
                <Button variant="outlined" size="lg" onClick={() => printLabel(d)} label="Print">
                  <PrintIcon style={{ marginRight: 2 }} />
                </Button>
              )}
            </Grid>

            <Grid item xs={7} spacing={2}>
              <LabelValue label="" value={`${d.firstName} ${d.lastName}`} grid={4} bigValue />
              <LabelValue label="" value={`${d.title}`} grid={8} />
              <LabelValue label="" value={`${d.company}, ${d.country}`} grid={8} />
            </Grid>
            <Grid item xs={5} spacing={2}>
              <LabelValue rightAlign label="Passes" value={d.passes?.join(", ")} />
            </Grid>
            <Grid item xs={12} spacing={2}>
              <LabelValue label="" value={d.phone} grid={4} />
              <LabelValue label="" value={d.email} grid={4} />
            </Grid>
          </Grid>
          <Grid container spacing={2}></Grid>
        </>
      ))}
    </div>
  );
};

export default InfoCard;
