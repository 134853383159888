import React from "react";
import { List, Datagrid, TextField, RichTextField, ImageField, EditButton } from "react-admin";
const SponsorPanel = () => <RichTextField source="bio" />;

export default (props) => (
  <List {...props} perPage={50} pagination={false}>
    <Datagrid rowClick="edit" expand={<SponsorPanel />}>
      <ImageField source="image" />
      <TextField source="name" />
      <TextField source="type" />
      <TextField source="panel" />
      <TextField source="priority" />
      <EditButton />
    </Datagrid>
  </List>
);
