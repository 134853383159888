import React from "react";

import { Menu, usePermissions, useResourceDefinitions } from "react-admin";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScannerTwoTone";
import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone";
import { getEventId } from "../utils";

const LayoutMenu = () => {
  const resources = useResourceDefinitions();
  const { permissions } = usePermissions();
  const currentEventId = getEventId();
  if (!currentEventId || !permissions) {
    return null;
  }

  return (
    <Menu
      sx={{
        marginTop: 0,
        borderRight: "1px solid rgba(0,0,0,0.1)",
        height: "100%",
      }}
    >
      {permissions?.eventDashboard?.view && (
        <Menu.DashboardItem color="primary" leftIcon={<DashboardTwoToneIcon />} />
      )}
      {Object.keys(resources).map((name) => (
        <Menu.ResourceItem name={name} key={name} />
      ))}
      {permissions?.scanqr?.view && (
        <Menu.Item to="/qrcheck" primaryText="Scan QR" leftIcon={<QrCodeScannerIcon />} />
      )}
    </Menu>
  );
};

export default LayoutMenu;
