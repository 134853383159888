import React from "react";
import { List, Datagrid, EditButton, TextField, BooleanField } from "react-admin";

export default (props) => (
  <List {...props} perPage={50} pagination={false}>
    <Datagrid rowClick="edit">
      <TextField source="templateName" />
      <TextField source="subject" />
      <BooleanField source="isActive" />
      <EditButton />
    </Datagrid>
  </List>
);
