import React from "react";
import { EditButton, useListContext } from "react-admin";
import ImageList from "@mui/material/ImageList";

import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import PresentToAllIcon from "@mui/icons-material/PresentToAll";
import DoneIcon from "@mui/icons-material/Done";
import ImageListItem from "@mui/material/ImageListItem";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";

import Button from "@mui/material/Button";

const styles = {
  container: {
    marginBottom: "100px",
  },
  grid: {
    marginTop: 2,
  },
  gridList: {
    margin: 0,
  },
  tileBar: {
    background: "",
  },
  placeholder: {
    backgroundColor: (theme) => theme.palette.grey[300],
    height: "100%",
  },
  price: {
    display: "inline",
    fontSize: "1em",
  },
  link: {
    color: "#fff",
  },
  tile: {
    padding: "1px",
    maxHeight: "350px",
  },
  media: {
    // height: 100,
  },
  card: {},
};

const getColsForWidth = (width) => {
  if (width === "xs") return 2;
  if (width === "sm") return 3;
  if (width === "md") return 4;
  if (width === "lg") return 5;
  return 5;
};

const LoadingGridList = () => (
  <ImageList cols={getColsForWidth("sm")} sx={styles.gridList}>
    <ImageListItem>
      <div sx={styles.placeholder} />
    </ImageListItem>
  </ImageList>
);

const CardGridList = ({ data }) => (
  <Grid item lg={3} xs={12} sx={styles.tile} key={data.id}>
    <Card sx={styles.card}>
      <CardContent style={{ background: "#0a1f31", minHeight: "8rem" }}>
        <Typography component="div" color="white">
          {data.question}
        </Typography>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
        {data.status === "CREATED" && (
          <Tooltip title="Activate Poll">
            <Button startIcon={<ToggleOnIcon />} size="small" color="success" onClick={() => {}}>
              <Typography color="primary">Activate</Typography>
            </Button>
          </Tooltip>
        )}
        {data.status === "ACTIVE" && (
          <Tooltip title="Open Presenter View">
            <Button
              startIcon={<PresentToAllIcon />}
              size="small"
              color="success"
              onClick={() => {
                window.open(`${window.location.href}-presenter/${data.id}`, "_blank");
              }}
            >
              <Typography color="primary">Live</Typography>
            </Button>
          </Tooltip>
        )}
        {data.status === "ENDED" && (
          <Tooltip title="Poll Ended View Stats">
            <Button
              startIcon={<DoneIcon />}
              size="small"
              color="success"
              onClick={() => {
                window.open(`${window.location.href}-presenter/${data.id}`, "_blank");
              }}
            >
              <Typography color="primary">Ended</Typography>
            </Button>
          </Tooltip>
        )}
        {data.status === "CREATED" && <EditButton resource="polls" record={data} />}
      </CardActions>
    </Card>
  </Grid>
);

const LoadedGridList = () => {
  const { data } = useListContext();

  if (!data) return null;
  return (
    <div style={styles.container}>
      {data.length > 0 && (
        <Grid container spacing={3} alignItems="center" sx={styles.grid}>
          {data.map((d) => (
            <CardGridList data={d} key={d.id} />
          ))}
        </Grid>
      )}
    </div>
  );
};

const GridList = ({ isLoading, ...props }) =>
  isLoading ? <LoadingGridList /> : <LoadedGridList {...props} />;

export default GridList;
