import React from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  FileInput,
  FileField,
  FormDataConsumer,
  useRecordContext,
  BooleanInput,
  useNotify,
  useUpdate,
} from "react-admin";
import Box from "@mui/material/Box";
import RichInput from "../../components/richInput";
import { uploadBase64Media, uploadMedia } from "../../dataprovider/uploadProps";
import ImageInputWithCrop from "../../components/ImageInputWithCrop";
// import ImageResize from "../../components/ImageResize";

export default (props) => {
  const notify = useNotify();
  const [update] = useUpdate();
  const save = async (data) => {
    let imageUrl = data.image;
    let companyImageUrl = data.companyImage;
    try {
      if (data.image) {
        imageUrl = await uploadBase64Media(data.image);
      }
      if (data.companyImage && data.companyImage instanceof Object) {
        companyImageUrl = await uploadMedia(data.companyImage, "speakers");
      }
    } catch (error) {
      notify("File is too large. max allowed size (100kb)", { type: "warning" });
      return;
    }
    await update(
      "speakers",
      { id: data.id, data: { ...data, image: imageUrl, companyImage: companyImageUrl } },
      { returnPromise: true }
    )
      .then(() => {
        notify("Speaker Updated");
      })
      .catch((err) => {
        console.error(err);
        notify("Something wrong happened. please try again", { type: "warning" });
      });
  };

  const SpeakerTitle = () => {
    const record = useRecordContext();
    return <span>{record ? `${record.name}` : ""}</span>;
  };

  return (
    <>
      <Edit {...props} mutationMode="optimistic" title={<SpeakerTitle />}>
        <SimpleForm warnWhenUnsavedChanges sx={{ maxWidth: 500 }} onSubmit={save}>
          <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <TextInput source="name" isRequired fullWidth />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <TextInput source="title" isRequired fullWidth />
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <TextInput source="highlightText" fullWidth />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <TextInput source="company" isRequired fullWidth />
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <TextInput source="country" isRequired fullWidth />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <TextInput source="phone" fullWidth />
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
            <Box flex={1}>
              <TextInput source="email" fullWidth />
            </Box>
          </Box>
          <BooleanInput source="isHighlighted" />

          <RichInput size="large" source="bio" title="Bio" />
          <ImageInputWithCrop source="image" label="Speaker Image" />
          <FileInput
            source="companyImage"
            label="Company Image"
            accept="image/jpeg, image/jpg, image/png"
            maxSize={50000}
            helperText={
              <span>
                Please upload an image of the following specifications - <br />
                <ol>
                  <li>aspect ratio:- 100px width</li>
                  <li>type:- JPG/JPEG</li>
                  <li> size:- less than 50kb</li>
                </ol>
              </span>
            }
          >
            <FileField source="src" title="title" />
          </FileInput>
          <FormDataConsumer>
            {({ formData }) =>
              formData.companyImage &&
              typeof formData.companyImage === "string" && (
                <img src={formData.companyImage} alt={formData.companyImage} width="100px" />
              )
            }
          </FormDataConsumer>
          {/* <ImageResize /> */}
          <BooleanInput source="hideSpeaker" />
        </SimpleForm>
      </Edit>
    </>
  );
};
