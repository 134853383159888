/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  ListContextProvider,
  SimpleList,
  useDataProvider,
  useList,
  useNotify,
} from "react-admin";
import TextInput from "@mui/material/TextField";
import PrintIcon from "@mui/icons-material/Print";
import CheckIcon from "@mui/icons-material/Check";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";

import { Card } from "@mui/material";
import debounce from "lodash.debounce";

export default ({ printLabel, onConfirm }) => {
  const dataProvider = useDataProvider();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const notify = useNotify();

  const getData = (val) => {
    dataProvider
      .getList("speakers", {
        pagination: { page: 1, perPage: 50 },
        sort: { field: "name", order: "ASC" },
        filter: { search: val || "" },
      })
      .then(({ data: values }) => {
        setData(values);
      })
      .catch((error) => {
        notify(error);
      });
  };

  const debouncedSave = useCallback(
    debounce((val) => getData(val), 1000),
    []
  );

  const handleChange = (e) => {
    setSearch(e.target.value);
    debouncedSave(e.target.value);
  };

  const confirmSpeaker = async (id) => {
    await onConfirm(id);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const listContext = useList({ data });

  return (
    <Grid container spacing={2} sx={{ height: "100%", padding: "0 1rem" }}>
      <Grid item lg={8} xs={12} sx={{ textAlign: "center" }}>
        <Card style={{ height: "100%", width: "100%", padding: "1rem" }}>
          <TextInput
            label="Search"
            variant="filled"
            fullWidth
            value={search}
            onChange={handleChange}
          />
          <ListContextProvider value={listContext}>
            <SimpleList
              leftAvatar={(record) =>
                record.image ? (
                  <img src={record.image} width="100%" alt={record.name} />
                ) : (
                  <img
                    src={`https://ui-avatars.com/api/?background=1a237e&color=fff&name=${record.name}`}
                    width="100%"
                    alt={record.name}
                  />
                )
              }
              primaryText={(record) => record.name}
              tertiaryText={(record) => (
                <div style={{ textAlign: "right" }}>
                  {record.scannedOn ? "SCANNED" : "NOT SCANNED"}
                  <br />
                  <br />
                  {record.scannedOn ? (
                    <Tooltip title=" Print">
                      <Button variant="outlined" onClick={() => printLabel(record)}>
                        <PrintIcon />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Confirm and Print">
                      <Button
                        variant="contained"
                        onClick={() => confirmSpeaker(record.id)}
                        label="Confirm"
                      >
                        <CheckIcon />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              )}
              secondaryText={(record) => (
                <>
                  {record.title}
                  <br />
                  {`${record.company}, ${record.country}`}
                  <br /> <br />
                  SPEAKER
                </>
              )}
              rowStyle={() => ({
                border: "1px solid #afafaf",
                borderRadius: "12px",
                marginBottom: "5px",
                padding: "12px",
              })}
              linkType={false}
            />
          </ListContextProvider>
        </Card>
      </Grid>
    </Grid>
  );
};
