import React from "react";
import { TextInput, Create, SimpleForm, PasswordInput, SelectInput } from "react-admin";

export default (props) => (
  <Create {...props}>
    <SimpleForm sx={{ maxWidth: 500 }}>
      <TextInput source="name" required fullWidth />
      <TextInput source="email" required fullWidth />
      <PasswordInput source="password" required fullWidth />
      <TextInput source="company" required fullWidth />
      <SelectInput
        source="access"
        required
        fullWidth
        choices={[
          { id: "FULL", name: "Full Access" },
          { id: "SCAN_ONLY", name: "Scan Only" },
        ]}
      />
    </SimpleForm>
  </Create>
);
