import React from "react";
import {
  TextInput,
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  Button,
} from "react-admin";

export default (props) => (
  <Create {...props} redirect="list">
    <SimpleForm sx={{ maxWidth: 500 }}>
      <TextInput source="question" fullWidth />
      <ArrayInput source="options" fullWidth>
        <SimpleFormIterator inline addButton={<Button variant="outlined">Add Options</Button>}>
          <TextInput source="option" helperText={false} fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
      <ReferenceInput
        source="agendaId"
        reference="agendas"
        perPage={100}
        sort={{ field: "day", order: "ASC" }}
      >
        <SelectInput
          label="Agenda"
          helperText="Add Poll to agenda item"
          fullWidth
          format={(value) => (value === undefined ? "not defined" : value)}
          optionText="title"
          sx={{ mb: 1 }}
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
